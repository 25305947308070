'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import angular = require("angular");
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {EVehicleType} from "../../../../data/vehicles.data";
import {RolePrivilege} from "../../../../data/privileges.enum";


require('./vehiclefault.component.css');

export default class VehicleFaultComponent {
  public restrict: any;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'EA',
      this.scope = {
        vehicle: '=',
      }
    this.template = require('./vehiclefault.component.html');

    this.controller = VehicleFaultComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class VehicleFaultComponentController {
  public helperService: HelperService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $log: ILogService;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public vehicleFaults: any;
  public vehicle: any;
  public hasOpenFault: boolean;
  public hasAddFault: boolean = false;

  constructor($rootScope: IRootScopeService, $scope: IScope, $log: ILogService, $uibModal, dataService, restService: RestService, helperService: HelperService,
              public privilegeService: PrivilegeService) {
    this.helperService = helperService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.vehicleFaults = [];

    this.$scope.$watch('ctrl.vehicle', (oldValue, newVehicle) => {
      if (angular.isDefined(newVehicle)) {
        
        if (this.vehicle.vehicleType === EVehicleType.SIREN) {
          this.hasAddFault = privilegeService.has(RolePrivilege.Station_Sirens_Faults_Create);
        } else {
          this.hasAddFault = privilegeService.has(RolePrivilege.Station_Vehicles_Faults_Create);
        }

        this.getFaultsFromServer();
      }
    });

    this.$rootScope.$on('faults.update', () => {
      this.getFaultsFromServer();
    })
  }

  getFaultsFromServer() {
    this.$log.debug('Got fault messages for vehicle from server')
    this.dataService.getVehicleFaults(this.vehicle, (vehicleFaults) => {
      this.vehicleFaults = vehicleFaults;
      this.hasOpenFault = vehicleFaults.filter(fault => fault.vehicleFaultState !== 'ELIMINATED').length > 0;

      this.$log.debug('Got fault messages for vehicle from server')
    })
  }

  open(vehicleFault) {
    this.$uibModal.open({
      template: require('../../../modals/wache/vehicleFault.modal/vehicleFault.modal.html'),
      controller: 'VehicleFaultModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicleFault: () => {
          return vehicleFault;
        },
        vehicle: () => {
          return this.vehicle;
        }
      }
    });
  };

  closeAll() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.modal/confirm.modal.html'),
      controller: 'ConfirmModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return () => {
            this.restService.closeAllVehicleFaults(this.vehicle).finally(() => {
              this.getFaultsFromServer();
            });
          };
        },
        text: () => {
          return 'VEHICLEFAULT.CLOSE_ALL';
        },
        info: () => {
          return "";
        }
      }
    });
  }
}
