'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {AlarmObject} from "../../../../data/objects.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {RolePrivilegeResponse} from "../../../../data/role.data";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import {el} from "@fullcalendar/core/internal-common";

require('./edit.alarm.object.modal.css');

/* @ngInject */
export default class EditAlarmObjectController {
  public $rootScope: any;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $log: any;
  public dataService: any;
  public helperService: HelperService;
  public account: UserAccount;
  public mode: string = 'PLACEHOLDER';
  public isAddressExpanded = false;
  public isBMZExpanded = false;
  public isDangerExpanded = false;
  public isOverallExpanded = false;
  public isRescueServicesExpanded = false;
  public alarmObject: AlarmObject;
  public alarmObjectId: string;
  public isEditable = false;
  public mapMode: any;
  public isLoading = false;
  public hasAtLeastOneEditPrivilege = false;
  public isAdmin = false;

  constructor($rootScope: IRootScopeService, $uibModal, $uibModalInstance, $log: ILogService, dataService,
              helperService: HelperService, alarmObject: AlarmObject, alarmObjectId: string,
              public privilegeService: PrivilegeService,
              private restService: RestService,
              private $scope: IScope,
              private readonly Notification: any,
              private readonly $translate: any
  ) {
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;
    this.$log.debug('Opening EditAlarmObjectController...');

    this.account = this.dataService.getAccount();
    if (dataService.hasAccount()) {
      this.isAdmin = dataService.getAccount().admin;
    }
    this.checkIfRoleHasAtLeastOneEditPrivilege();
    if (alarmObject) {
      this.alarmObject = alarmObject;
      this.alarmObjectId = alarmObject.id;
      //Is editable?
      this.isEditable = this.account.username === this.alarmObject.parent;
      this.mode = this.calculateFirstMode();
    } else {
      this.alarmObjectId = alarmObjectId;
      //Is editable?
      this.isEditable = false;
    }
    this.$log.debug('IsEditable: ' + this.isEditable);
    this.mapMode = 'ADDRESS';

    // Load
    this.isLoading = true;
    this.dataService.getAlarmObjectById(this.alarmObjectId, (alarmObject) => {
      this.alarmObject = alarmObject;
      this.$log.info(alarmObject);
      this.isLoading = false;
      this.isEditable = this.account.username === this.alarmObject.parent;
      this.mode = this.calculateFirstMode();
    }, (response) => {
      //Error occured
      this.$log.error(response);
      this.isLoading = false;
    });

  }

  calculateFirstMode(): string {
    if (this.privilegeService.has(RolePrivilege.Objects_Database_General_Read)) {
      this.isOverallExpanded = true;
      return 'OVERALL';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Address_Read)) {
      this.isAddressExpanded = true;
      return 'ADDRESS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_BMA_Read)) {
      this.isBMZExpanded = true;
      return 'BMZ';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_HomeEmergency_Read)) {
      return 'HOME_EMERGENCY';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_LockingSystem_Read)) {
      return 'LOCKING_SYSTEM';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Hazards_Read)) {
      this.isDangerExpanded = true;
      return 'DANGER';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Radio_Read)) {
      return 'RADIO';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Safety_Read)) {
      return 'SECURITY';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Contacts_Read)) {
      return 'CONTACTS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Events_Read)) {
      return 'EVENTS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Documents_Read)) {
      return 'DOCUMENTS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Subobject_Read)) {
      return 'BUILDINGS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Markers_Read)) {
      return 'PATHS';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Map_Read)) {
      return 'MAP';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_Notes_Read)) {
      return 'NOTES';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_History_Read)) {
      return 'PROTOCOL';
    }
    if (this.privilegeService.has(RolePrivilege.Objects_Database_RescueServices_Read)) {
      this.isRescueServicesExpanded = true;
      return 'RESCUE_SERVICES_NORMAL';
    }
  }

  selectMapMode(mode) {
    this.mapMode = mode;
    this.$rootScope.$emit('alarm.object.map.mode', {
      mode: mode
    });
  }

  changeMode(mode) {
    this.mode = mode;
  };

  /**
   * Expand the menu
   */
  expand(ex: string, isCurrentlyExpanded: boolean) {
    this.isAddressExpanded = false;
    this.isBMZExpanded = false;
    this.isOverallExpanded = false;
    this.isDangerExpanded = false;
    this.isRescueServicesExpanded = false;
    switch (ex) {
      case 'DANGER':
        this.isDangerExpanded = !isCurrentlyExpanded;
        break;
      case 'BMZ':
        this.isBMZExpanded = !isCurrentlyExpanded;
        break;
      case 'ADDRESS':
        this.isAddressExpanded = !isCurrentlyExpanded;
        break;
      case 'OVERALL':
        this.isOverallExpanded = !isCurrentlyExpanded;
        break;
      case 'RESCUE_SERVICES':
        this.isRescueServicesExpanded = !isCurrentlyExpanded;
        break;
    }
  };

  cancel() {
    this.$uibModalInstance.close();
  };

  save() {
    this.saveAndAssign(false);
  }

  /**
   * Save object
   */
  saveAndAssign(assign: boolean) {
    this.isLoading = true;
    // allow admin to share alarm objects
    // saving alarm objects will fail due to permission issues of objects owner
    if (this.account.admin && !this.isEditable) {
      if (assign) {
        this.assignAllUsers();
      }
    } else {

      this.dataService.updateAlarmObject(this.alarmObject, (alarmObject) => {
        this.alarmObject = alarmObject;
        this.$log.info(alarmObject);
        this.isLoading = false;
        this.$rootScope.$emit(alarmObject.id + '.nbrOfNewFeedback', alarmObject.nbrOfNewFeedback);
        if (assign) {
          this.assignAllUsers();
        }
      }, (response) => {
        //Error occured
        this.$log.error(response);
        this.isLoading = false;
      });
    }

  }

  assignAllUsers() {
    this.restService.alarmObjectsAssignAllUsers(this.alarmObject.id).then((res) => {
      this.Notification.success({
        message: res.message
      });
      this.$uibModalInstance.close();
    });
  }

  selectMap() {
    this.$rootScope.$emit('map.address.update');
  }

  checkIfRoleHasAtLeastOneEditPrivilege(): void {
    const editPrivileges: RolePrivilege[] = this.getEditPrivileges();
    // if none of these privileges is set, .find() returns undefined
    this.hasAtLeastOneEditPrivilege = editPrivileges.find(p => this.privilegeService.has(p)) !== undefined;
  }

  private getEditPrivileges(): RolePrivilege[] {
    return [
      RolePrivilege.Objects_Database_Address_Edit,
      RolePrivilege.Objects_Database_BMA_Edit,
      RolePrivilege.Objects_Database_Buildings_Edit,
      RolePrivilege.Objects_Database_ChangeRequests_Edit,
      RolePrivilege.Objects_Database_Contacts_Edit,
      RolePrivilege.Objects_Database_DirectionCards_Edit,
      RolePrivilege.Objects_Database_DirectionCards_Delete,
      RolePrivilege.Objects_Database_Documents_Edit,
      RolePrivilege.Objects_Database_Events_Edit,
      RolePrivilege.Objects_Database_General_Edit,
      RolePrivilege.Objects_Database_Subobject_Edit,
      RolePrivilege.Objects_Database_Safety_Edit,
      RolePrivilege.Objects_Database_Radio_Edit,
      RolePrivilege.Objects_Database_Notes_Write,
      RolePrivilege.Objects_Database_Markers_Edit,
      RolePrivilege.Objects_Database_Hazards_Edit,
      RolePrivilege.Objects_Database_HomeEmergency_Edit,
      RolePrivilege.Objects_Database_LockingSystem_Edit,
      RolePrivilege.Objects_Database_RescueServices_Edit
    ];
  }

  /**
   * Delete object
   */
  delete() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteAlarmObjectById(this.alarmObject, () => {
              this.isLoading = false;
              this.$rootScope.$emit('objects.deleted', this.alarmObject);
              this.$uibModalInstance.close();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

  resetExamination() {
    this.restService.resetExaminationForAlarmObject(this.alarmObject.id)
      .then((result) => this.$translate(['EXAMINATIONS.RESET_SUCCESS']).then(translations =>
        this.Notification.success({
          title: translations['EXAMINATIONS.RESET_SUCCESS'],
          message: result.message
        })
      ))
      .catch(err => this.$log.error(err.data.message));
  }

  /**
   *
   * @returns Return true, if this object was imported from IVENA
   */
  isIvenaImport(): boolean {
    if (!this.alarmObject) {
      return false;
    }
    if (!this.alarmObject.hasLink) {
      return false;
    }

    if (!this.alarmObject.links) {
      return false;
    }

    return this.alarmObject.links.filter(link => link.system === 'IVENA').length > 0;
  }
}
